.lead-details-container {
  .nav-underline-scrollspy {
    z-index: 1014;
    top: calc(var(--#{$prefix}navbar-top-height) + 1px);
  }
}
.sticky-leads-sidebar {
  position: sticky;
  z-index: 1015;
  top: calc(var(--#{$prefix}navbar-top-height) + 21px);
}
.leads-table {
  th {
    position: relative;
    &::after {
      position: absolute;
      top: 35%;
    }
  }
}

.lead-details-offcanvas {
  max-width: 350px;
  top: var(--#{$prefix}navbar-top-height) !important;
  height: calc(100vh - var(--#{$prefix}navbar-top-height));
  padding: map-get($spacers, 4);
  padding-top: 1rem;
  @include media-breakpoint-up(md) {
    position: static !important;
    top: 1rem !important;
    padding: 0 !important;
    transform: none !important;
    max-width: unset;
    height: auto;
  }
}
.icon-wrapper-sm {
  position: relative;
  display: inline-block;
  &::before {
    position: absolute;

    content: '';
    height: 1.299rem;
    width: 1.299rem;
    border-radius: 0.243rem;
    top: 0.5rem;
    left: 0.2rem;
  }
}

.shadow-primary-100 {
  &:before {
    background-color: rgba(var(--#{$prefix}primary-rgb), 0.3);
  }
}
.shadow-info-100 {
  &:before {
    background-color: rgba(var(--#{$prefix}info-rgb), 0.3);
  }
}

.list-group-item {
  &.list-group-crm {
    padding: 0.3rem 0;
  }
}
